import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {

  const filters = {
    Clients: "Клиентам",
    Professionals: "Специалистам"
  };

  const faqsData = [
    {
      question: "Как попасть к Вам на консультацию?",
      answer:
          "Запись на консультации происходит по факту Вашего обращения ко мне и при наличии свободных окошек для записи. " +
          "Вы можете написать мне в мессенджеры - " +
          "<a href='https://t.me/A_Shoshina' target='_blank' rel='noopener noreferrer'>Telegram</a>, " +
          "<a href='https://wa.link/0jt9a0' target='_blank' rel='noopener noreferrer'>WhatsApp</a>, " +
          "где мы обговорим возможные на данный момент варианты окошек для записи. " +
          "Также на моём сайте есть форма - «Лист потенциальных клиентов». " +
          "Через неё Вы можете оставить мне заявку, которая автоматически придёт мне на почту.",
      categories: filters.Clients
    },
    {
      question: "Сколько стоит консультация?",
      answer:
          "С актуальной стоимостью индивидуальной консультации Вы всегда можете ознакомиться на данном сайте. (Услуги и Цены)",
      categories: filters.Clients
    },
    {
      question: "Сколько длится консультация?",
      answer:
          "Индивидуальная консультация длится 50 минут, семейная консультация - 90 минут.",
      categories: filters.Clients
    },
    {
      question: "Какое количество консультаций нужно для решения моего запроса?",
      answer:
          "Продолжительность и частота психотерапии зависит от характера Ваших проблем и индивидуальных потребностей." +
          "Количество сессий оговаривается в индивидуальном порядке." +
          "В длительной терапии количество сессий может составлять от 10 консультаций и более. При решении ситуативных трудностей - от 1 консультации и более.",
      categories: filters.Clients
    },
    {
      question: "Есть ли возможность попасть на разовую сессию?",
      answer:
          "Да, места для разовых сессий время от времени появляются. Вы всегда можете узнать об их наличии написав мне " +
          "напрямую в мессенджеры - " +
          "<a href='https://t.me/A_Shoshina' target='_blank' rel='noopener noreferrer'>Telegram</a>, " +
          "<a href='https://wa.link/0jt9a0' target='_blank' rel='noopener noreferrer'>WhatsApp</a>, " +
          ". Если возможность записи есть, то я всегда с радостью предложу " +
          "Вам свободное окошко для разовой сессии.",
      categories: filters.Clients
    },
    {
      question: "Могу ли я пропустить консультацию?",
      answer:
          "Да, Вы вправе перенести (при подтверждении со стороны специалиста возможности переноса в рамках текущей недели) " +
          "или отменить время назначенной консультации не менее, чем за 24 часа до назначенного времени." +
          "В случае пропуска или отмены консультации позднее чем за 24 часа, сессия оплачивается.",
      categories: filters.Clients
    },
    {
      question: "Работает ли Вы с детьми и подростками?",
      answer:
          "Нет, на данный момент приём детей и подростков приостановлен.",
      categories: filters.Clients
    },
    {
      question: "Какие форматы записи у Вас есть?",
      answer:
          "На данный момент все консультации проводятся в онлайн формате. Вы можете записаться на розовую сессию или в длительную терапию при наличии свободных окошек для записи.",
      categories: filters.Clients
    },
    {
      question: "Как происходит оплата консультаций?",
      answer:
          "Оплата онлайн консультации производится в назначенный день до сеанса. Перевод можно совершить на карты - Сбербанк/Тинькофф.",
      categories: filters.Clients
    },
    {
      question: "Можно оплатить другой валютой?",
      answer:
          "Да, оплата другой валютой возможна и обсуждается индивидуально при записи на консультацию.",
      categories: filters.Clients
    },
    {
      question: "Какие направления психологии Вы используете в своей работе?",
      answer:
          "В своей работе я использую знания из разных областей психотерапии и психологии: " +
          "1. Транзитный анализ (моя основная модальность); " +
          "2. Системная семейная психотерапия; " +
          "3. Арт-терапия; " +
          "4. Бодинамика; " +
          "5. Плассотерапия (песочная терапия); " +
          "6. Интуитивная живопись; " +
          "7. Метод работы с травмой идентичности профессора Ф.Рупперта; " +
          "8. Репродуктивная и пренатальная психотерапия; "+
          "9. Профориентация; " +
          "10. Основы психиатрии и многое другое. ",
      categories: filters.Professionals
    },
    {
      question: "В чём разница психотерапии, ОЛТ и супервизии?",
      answer:
          "Хочу порекомендовать Вам ознакомиться с моим видео-ответом на данный вопрос по ссылке -",
      categories: filters.Professionals
    },
    {
      question: "Выдаёте ли сертификат учёта часов?",
      answer:
          "ОЛТ и полимодальная супервизия проводятся с учётом часов, которые отображаются в накопительном сертификате " +
          "от Общероссийской Профессиональной Психотерапевтической Лиги (ОППЛ). Они признаются большинством " +
          "международных ассоциаций психотерапии, т.к. являются зонтичной организацией Европейской Ассоциации " +
          "Психотерапии (ЕАП).",
      categories: filters.Professionals
    },
    {
      question: "Как происходит оплата?",
      answer:
          "Оплата индивидуальных форматов производится в назначенный день до встречи. Групповые форматы могут " +
          "иметь как разовый формат оплаты до встречи, так и частями по личной договорённости между специалистом и мной.",
      categories: filters.Professionals
    },
    {
      question: "Что выбрать группу или индивидуальный формат?",
      answer:
          "Многое зависит от Ваших предпочтений, временных и материальных вложений. Я бы рекомендовала попробовать оба формата.",
      categories: filters.Professionals
    },
    {
      question: "Я не психолог. Могу ли я принять участие в группе ОЛТ?",
      answer:
          "Да, если Вы являетесь специалистом помогающей профессии (тип человек-человек). Более точный ответ по " +
          "вашей профессии Вы можете получить написав мне в мессенджеры - " +
          "<a href='https://t.me/A_Shoshina' target='_blank' rel='noopener noreferrer'>Telegram</a>, " +
          "<a href='https://wa.link/0jt9a0' target='_blank' rel='noopener noreferrer'>WhatsApp</a>.",
      categories: filters.Professionals
    }
  ];

  const individualFaqsData = faqsData.filter(
      (faq) => faq.categories === filters.Clients
  );

  const professionalFaqsData = faqsData.filter(
      (faq) => faq.categories === filters.Professionals
  );

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-8 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">Популярные вопросы</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Остались вопросы?
            </h2>
            {/* Heading end*/}
            {/* Filter Menu */}
            <div className="nav-pills mb-3 text-center text-xl-start" id="pills-tab" role="tablist">
              <div className="btn-group btn-group-lg nav-item" role="presentation">
                <button className="btn btn-outline-dark nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                        aria-selected="true">Клиентам
                </button>
                <button className="btn btn-outline-dark nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false">Специалистам
                </button>
              </div>
            </div>
            {/* Filter Menu end */}
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                   aria-labelledby="pills-home-tab" tabIndex="0">
                <Accordion flush defaultActiveKey="0">
                  {individualFaqsData.length > 0 &&
                      individualFaqsData.map((faq, index) => (
                          <Accordion.Item eventKey={index} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body
                                dangerouslySetInnerHTML={{ __html: faq.answer }}
                            ></Accordion.Body>
                          </Accordion.Item>
                      ))}
                </Accordion>
              </div>
              <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
                   tabIndex="0">
                <Accordion flush defaultActiveKey="0">
                  {professionalFaqsData.length > 0 &&
                      professionalFaqsData.map((faq, index) => (
                          <Accordion.Item eventKey={index} key={individualFaqsData.length+index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body
                                dangerouslySetInnerHTML={{ __html: faq.answer }}
                            ></Accordion.Body>
                          </Accordion.Item>
                      ))}
                </Accordion>
              </div>
            </div>
          </div>
          <div
              className="col-lg-4 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
              data-wow-delay="1s"
          >
            {" "}
            <img
                className="img-fluid"
                width="200px"
                src="images/faq.webp"
                title="FAQ"
                alt="faq"
                loading="lazy"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
