import React from "react";

const Schedule = () => {

  return (
      <section id="schedule" className={"section"}>
        <div className="container text-center">
          <div className="col-lg-12">
            {/* Heading */}
            <p className="mb-2 wow fadeInUp">
              <span className="bg-primary text-dark px-2">Расписание курса</span>
            </p>
            <h2 className="text-10 fw-600 mb-5 wow fadeInUp">
              Ознакомьтесь с датами ближайших встреч
            </h2>
            {/* Heading end*/}
          </div>
          {/* Content */}
          <div className="row text-start">
            <div className="col-lg-1"/>
            <div className="alert alert-success col-lg-4 col-sm-12" role="alert">
              <ul>
                <li>Продолжительность курса: 2,5 месяца.</li>
                <li>Частота встреч: 1 раз в неделю (по средам).</li>
                <li>Время: 10:00-12:00 по мск.</li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-12">
              <table className="table">
                <thead>
                <tr>
                  <th scope="col"><b>Месяц</b></th>
                  <th scope="col"><b>Даты встреч</b></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">Сентябрь</th>
                  <td>Среда, 10:00-12:00 по мск. 20.09.23, 27.09.23</td>
                </tr>
                <tr>
                  <th scope="row">Октябрь</th>
                  <td>Среда, 10:00-12:00 по мск. 04.10.23, 11.10.23, 18.10.23, 25.10.23</td>
                </tr>
                <tr>
                  <th scope="row">Ноябрь</th>
                  <td>Среда, 10:00-12:00 по мск. 01.11.23, 08.11.23, 15.11.23, 22.11.23, 29.11.23</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Schedule;
