import React from "react";

const Benefits = () => {
  
  const benefits = [
    {
      title: "Найти Фокус Внимания в Работе",
      details:
          "Сфокусировать своё внимание на одном из главных элементов терапии - клиентском запросе",
    },
    {
      title: "Научиться Работать с Фокусом",
      details:
          "Понять ценность клиентского запроса и расширить знания в данной теме, которые помогут специалисту " +
          "сопроводить клиента к формированию наиболее эффективного запроса для дальнейшей работы",
    },
    {
      title: "Работать с Конструктивными запросами",
      details:
          "Научиться отделять конструктивный клиентский запрос от неконструктивного и определять их типы",
    },
    {
      title: "Работать с Мотивацией Клиента",
      details:
          "Разобрать влияние клиентской мотивации на формирование клиентского запроса",
    },
    {
      title: "Работать с Собственной Позицией",
      details:
          "Понять и разобрать собственную профессиональную позицию при формировании запроса клиентом",
    },
    {
      title: "Оипробовать техники на себе",
      details:
          "Познакомиться и применить на себе техники для прояснения клиентского запроса",
    },
    {
      title: "Работать с Парами и Группами",
      details:
          "Получить возможность отработать в парах и группах навыки формирования клиентских запросов",
    }
  ];
  return (
      <section id="benefits" className="section bg-light">
        <div className="container text-center">
          <div className="col-lg-12">
            {/* Heading */}
            <p className="mb-2 wow fadeInUp">
              <span className="bg-primary text-dark px-2">Возможности курса</span>
            </p>
            <h2 className="text-10 fw-600 mb-5 wow fadeInUp">
              Курс дает возможность специалистам
            </h2>
            {/* Heading end*/}
          </div>
          {/* Content */}
          <div className="row">
            <div className="col-lg-2"/>
            <div className="text-start col-lg-8 lh-4">
                {benefits.length > 0 &&
                    benefits.map((rule, index) => (
                        <span
                            type="button" className="fa fa-check-circle text-start"
                        >
                          {" "}{rule.details}<br/>
                          <hr/>
                        </span>
                    ))}
            </div>
            <div className="col-lg-2"/>
          </div>
      </div>
    </section>
  );
};

export default Benefits;
