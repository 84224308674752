export const introBackgroundConfig = {
  default: "default",
  image: "image",
  video: "video",
};

export const appliedConfig = {

  // apply Intro from : default | image | video
  appliedIntro: introBackgroundConfig.default,

};

export const scrollDuration = 1000;
