import React from "react";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-scroll";
import {scrollDuration} from "../../config/commonConfig";

const Services = ({individualPrice, scriboPrice, pairPrice}) => {

  const filters = {
    Individual: "Клиентам",
    Professionals: "Специалистам",
    Groups: "Групповые Форматы"
  };
  // services details
  const services = [
    {
      shortName: "Индивидуальная Психологическая Консультация",
      desc: "Вы столкнулись с временными трудностями? Оказались в трудной жизненной ситуации? " +
          "Ищете способ воплотить свои мечты, связанные с отношениями, работой, самореализацией? " +
          "Обстоятельства выбили Вас из равновесия? Вы можете прийти ко мне на консультацию для того, " +
          "чтобы помочь себе справиться с этим.",
      checkMarks: [
          "50 минут",
          "Формат: Онлайн встреча"
      ],
      icon: "fa fa-bullseye",

      price: individualPrice,
      format: filters.Individual
    },
    {
      shortName: "Психологическое Консультирование Пар",
      desc: " Парой я называю двух людей, которые находятся вместе и объединены чем- то. " +
          "Это могут быть различные пары: супружеские; мужчина и женщина, планирующие создать семью.",
      checkMarks: [
          "90 минут.",
          "Формат: Онлайн встреча."
      ],
      icon: "fa fa-heartbeat",
      price: pairPrice,
      format: filters.Individual
    },
    {
      shortName: "Скриботерапия \n (терапия в письме)",
      desc: "Психологическая консультация по переписке. Вы пишите письмо и получаете ответ от психолога в заранее установленные сроки.",
      checkMarks: [
        "По асинхронной переписке",
        "В чате в режиме реального времени (50 минут)"
      ],
      icon: "fa fa-child",
      price: scriboPrice,
      format: filters.Individual
    },
    {
      shortName: "Обучающая Личная терапия",
      desc: "Личная терапия может быть важным процессом в рамках познания себя для психологов, врачей, педагогов, " +
          "социальных работников, дефектологов, логопедов, работающих со сложными детьми, работников сферы человек-человек.",
      checkMarks:  [
        "50 минут.",
        "Формат: Онлайн встреча."
      ],
      icon: "fa fa-handshake",
      price: individualPrice,
      format: filters.Professionals
    },
    {
      shortName: "Полимодальная Супервизия",
      desc: "Данный формат подойдет Вам, если Вы стремитесь к повышению своего профессионального уровня. " +
          "Группа, сохраняя Ваш индивидуальный стиль в терапии, поможет скорректировать свою работу в текущей " +
          "и последующей психологической практике.",
      checkMarks:  [
        "50 минут.",
        "Формат: Онлайн встреча."
      ],
      icon: "fa fa-chart-line",
      price: individualPrice,
      format: filters.Professionals
    },
    {
      shortName: "Группа обучающей личной терапии",
      desc: "Группа обучающей личной терапии",
      checkMarks: [
        "50 минут.",
        "Формат: Онлайн встреча."
      ],
      icon: "fa fa-users",
      price: "Цена по запросу",
      format: filters.Groups
    },
    {
      shortName: "Группа полимодальной супервизии",
      desc: "Группа полимодальной супервизии",
      checkMarks: [
        "50 минут.",
        "Формат: Онлайн встреча."
      ],
      icon: "fa fa-users",
      price: "Цена по запросу",
      format: filters.Groups
    },
    {
      shortName: "Интуитивные арт-группы для специалистов",
      desc: "Интуитивные арт-группы для специалистов",
      checkMarks: [
        "50 минут.",
        "Формат: Онлайн встреча."
      ],
      icon: "fa fa-users",
      price: "Цена по запросу",
      format: filters.Groups
    }
  ];
  
  const individualServicesData = services.filter(
      (service) => service.format === filters.Individual
  );

  const professionalServicesData = services.filter(
      (service) => service.format === filters.Professionals
  );
  
  const groupServicesData = services.filter(
      (service) => service.format === filters.Groups
  );

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Услуги и цены</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Чем я могу помочь?
        </h2>
        {/* Heading end*/}
        <div className="text-center text-xl-start text-10 text-sm-10 nav-pills mb-3 d-flex d-sm-none" id="services-ind-tab" role="tablist">
          <div className="dropdown-item nav-link active" id="pills-home-tab" data-bs-toggle="pill"
             data-bs-target="#services-ind" aria-controls="services-ind"
             aria-selected="true">
            <i className="fa fa-user"></i>
          </div>
          <div className="dropdown-item nav-link" id="services-Pro-tab" data-bs-toggle="pill"
             data-bs-target="#services-pro" role="tab" aria-controls="services-pro"
             aria-selected="false" >
            <i className="fa fa-suitcase"></i>
          </div>
          <div className="dropdown-item nav-link" id="services-Group-tab" data-bs-toggle="pill"
             data-bs-target="#services-group" role="tab" aria-controls="services-group"
             aria-selected="false">
            <i className="fa fa-users"></i>
          </div>
        </div>
        <div className="text-center text-3 text-sm-10 nav-pills mb-3 d-sm-block d-none" id="services-ind-tab" role="tablist">
          <div className="btn-group btn-group-lg nav-item" role="presentation">
            <button className="btn btn-outline-dark nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                    data-bs-target="#services-ind" type="button" role="tab" aria-controls="services-ind"
                    aria-selected="true">{filters.Individual}
            </button>
            <button className="btn btn-outline-dark nav-link" id="services-Pro-tab" data-bs-toggle="pill"
                    data-bs-target="#services-pro" type="button" role="tab" aria-controls="services-pro"
                    aria-selected="false">{filters.Professionals}
            </button>
            <button className="btn btn-outline-dark nav-link" id="services-Group-tab" data-bs-toggle="pill"
                    data-bs-target="#services-group" type="button" role="tab" aria-controls="services-group"
                    aria-selected="false">{filters.Groups}
            </button>
          </div>
        </div>
        <div className="tab-content row" id="services-tabContent">
          <div className="tab-pane active" id="services-ind" role="tabpanel">
            <h4 className="d-block d-sm-none text-center">{filters.Individual}<hr/></h4>
            <div className="row">
            {individualServicesData.length > 0 &&
                individualServicesData.map((service, index) => (
                  <div className="col-lg-4 col-md-12 mb-4">
                    <div className="fade show card h-100 shadow-lg rounded-5"
                         aria-labelledby="services-ind-tab" tabIndex="0">
                      <div className="text-center m-2 mt-4">
                        <div className="featured-box-icon text-primary text-13">
                          {" "}
                          <i className={service.icon} />
                        </div>
                        <h3 className="text-6 fw-600 mb-3">{service.shortName.split('\n').map(str => (
                              <p 
                                style={{ marginBottom: "0px" }}
                              >
                                {str+" "}
                              </p>
                            ))}</h3>
                      </div>
                      <div className="text-center">
                        <h3 className="text-6 fw-600 mb-3 pt-4 pb-2 text-primary">{service.price}</h3>
                      </div>
                      <div className="card-body">
                        <ul className="list-group list-group-flush">
                          {service.checkMarks.length > 0 &&
                              service.checkMarks.map((checkMark, index) => (
                                  <li className="list-group-item">
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                    {"   "}
                                    {checkMark}
                                  </li>
                              ))}
                          <Accordion className="list-unstyled" flush defaultActiveKey="0">
                            <Accordion.Item>
                              <Accordion.Header>Узнать больше</Accordion.Header>
                              <Accordion.Body>
                                <p className="text-justify text-muted mb-0">{service.desc} </p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </ul>
                      </div>
                      <div className="text-center">
                        <Link
                            className="btn btn-primary rounded-3 smooth-scroll mb-4 wow rubberBand"
                            data-wow-delay="1s"
                            smooth="easeInOutQuint"
                            duration={scrollDuration}
                            to="contact"
                        >
                          Свяжитесь со мной
                        </Link>{" "}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
        </div>
          <div className="tab-pane" id="services-pro" role="tabpanel">
            <h4 className="d-block d-sm-none text-center">{filters.Professionals}<hr/></h4>
            <div className="row">
            {professionalServicesData.length > 0 &&
                professionalServicesData.map((service, index) => (
                    <div className="col-lg-4 col-md-12 mb-4">
                      <div className="fade show card h-100 shadow-lg rounded-5" 
                           aria-labelledby="services-Pro-tab" tabIndex="0">
                        <div className="text-center m-2 mt-4">
                          <div className="featured-box-icon text-primary text-13">
                            {" "}
                            <i className={service.icon} />
                          </div>
                          <h3 className="text-6 fw-600 mb-3">{service.shortName.split('\n').map(str => (
                              <p 
                                style={{ marginBottom: "0px" }}
                              >
                                {str+" "}
                              </p>
                            ))}</h3>
                        </div>
                        <div className="text-center">
                          <h3 className="text-6 fw-600 mb-3 pt-4 pb-2 text-primary">{service.price}</h3>
                        </div>
                        <div className="card-body">
                          <ul className="list-group list-group-flush">
                            {service.checkMarks.length > 0 &&
                                service.checkMarks.map((checkMark, index) => (
                                    <li className="list-group-item">
                                      <i className="fa fa-check" aria-hidden="true"></i>
                                      {"   "}
                                      {checkMark}
                                    </li>
                                ))}
                            <Accordion className="list-unstyled" flush defaultActiveKey="0">
                              <Accordion.Item>
                                <Accordion.Header>Узнать больше</Accordion.Header>
                                <Accordion.Body>
                                  <p className="text-justify text-muted mb-0">{service.desc} </p>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </ul>
                        </div>
                        <div className="text-center">
                          <Link
                              className="btn btn-primary rounded-3 smooth-scroll mb-4 wow rubberBand"
                              data-wow-delay="1s"
                              smooth="easeInOutQuint"
                              duration={scrollDuration}
                              to="contact"
                          >
                            Свяжитесь со мной
                          </Link>{" "}
                        </div>
                      </div>
                    </div>
                ))}
          </div>
        </div>
          <div className="tab-pane" id="services-group" role="tabpanel">
            <h4 className="d-block d-sm-none text-center">{filters.Groups}<hr/></h4>
            <div className="row">
              {groupServicesData.length > 0 &&
                  groupServicesData.map((service, index) => (
                      <div className="col-lg-4 col-md-12 mb-4">
                        <div className="fade show card h-100 shadow-lg rounded-5"
                             aria-labelledby="services-Group-tab" tabIndex="0">
                          <div className="text-center m-2 mt-4">
                            <div className="featured-box-icon text-primary text-13">
                              {" "}
                              <i className={service.icon} />
                            </div>
                            <h3 className="text-6 fw-600 mb-3">{service.shortName.split('\n').map(str => (
                              <p 
                                style={{ marginBottom: "0px" }}
                              >
                                {str+" "}
                              </p>
                            ))}</h3>
                          </div>
                          <div className="text-center">
                            <h3 className="text-6 fw-600 mb-3 pt-4 pb-2 text-primary">{service.price}</h3>
                          </div>
                          <div className="card-body">
                            <ul className="list-group list-group-flush">
                              {service.checkMarks.length > 0 &&
                                  service.checkMarks.map((checkMark, index) => (
                                      <li className="list-group-item">
                                        <i className="fa fa-check" aria-hidden="true"></i>
                                        {"   "}
                                        {checkMark}
                                      </li>
                                  ))}
                              <Accordion className="list-unstyled" flush defaultActiveKey="0">
                                <Accordion.Item>
                                  <Accordion.Header>Узнать больше</Accordion.Header>
                                  <Accordion.Body>
                                    <p className="text-justify text-muted mb-0">{service.desc} </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </ul>
                          </div>
                          <div className="text-center">
                            <Link
                                className="btn btn-primary rounded-3 smooth-scroll mb-4 wow rubberBand"
                                data-wow-delay="1s"
                                smooth="easeInOutQuint"
                                duration={scrollDuration}
                                to="contact"
                            >
                              Свяжитесь со мной
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
