import React from "react";
import Accordion from "react-bootstrap/Accordion";
import CustomToggle from "../Common/Toggle";

const Program = () => {

  const exercises = [
    {
      title: "Занятие 1-2",
      details:
          "Знакомство. Понятия «запрос», «жалоба», «нужда», «проблема», «самодиагноз».",
    },
    {
      title: "Занятие 3",
      details:
          "Ценность формулирования и соприкосновение с клиентским запросом при первом контакте с клиентом. " +
          "Первичный запрос и его значение для работы специалиста. Истинный (вторичный) запрос клиента.",
    },
    {
      title: "Занятие 4",
      details:
          "Проявление глубинного (рабочего) запроса клиента в процессе терапии. Переформулирование запроса.",
    },
    {
      title: "Занятие 5-6",
      details:
          "Типы клиентских запросов. Конструктивные и неконструктивные клиентские запросы.",
    },
    {
      title: "Занятие 7",
      details:
          "Основной и ситуативный клиентский запрос в длительной работе с клиентом.",
    },
    {
      title: "Занятие 8-9",
      details:
          "Мотивация клиента к изменениям и исследование клиентского запроса. Позиция консультанта в " +
          "процессе прояснения и формирования запроса.",
    },
    {
      title: "Занятие 10-11",
      details:
          "Техники прояснения клиентского запроса. Завершение обучения.",
    }
  ];

  return (
    <section id="program" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Программа курса</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Что в себя включает курс?
        </h2>
        {/* Heading end*/}
        <Accordion flush defaultActiveKey="0">
          {exercises.length > 0 &&
              exercises.map((rule, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header className="text-start text-xl-end">
                      <CustomToggle eventKey="1">{rule.title}</CustomToggle>
                    </Accordion.Header>
                    <Accordion.Body
                        dangerouslySetInnerHTML={{ __html: rule.details }}
                    ></Accordion.Body>
                  </Accordion.Item>
              ))}
        </Accordion>
      </div>
    </section>
  );
};

export default Program;
