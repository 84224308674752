import React from "react";
import {Parallax} from "react-parallax";
const About = () => {
  return (
      <section id="about">
        <div className="hero-wrap">
          <div className="hero-mask opacity-8 bg-dark" />
          <Parallax
              bgImage="images/courses_bg.jpeg"
              bgImageAlt="Course"
              strength={200}
              loading="lazy"
          >
            <div className="hero-content section container text-white">
              {/* Heading */}
              <p className="text-center mb-2 wow fadeInUp">
                <span className="bg-white text-dark px-2">О курсе</span>
              </p>
              <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
                Курс: «Мастерство формирования клиентского запроса»
              </h2>
              {/* Heading end*/}
              <div className="row">
                <div className="col-lg-1"/>
                <div className="col-lg-6 text-start text-lg-start wow fadeInUp">
                  <p className="text-4">
                    Для психологов и студентов психологических факультетов. Курс включает в себя 11 занятий 
                    направленных на изучение теории и практики формирования клиентского запроса. Продолжительность 
                    занятия 2 часа. Встречи проходят раз в неделю. По окончании курса Вы получаете электронный 
                    сертификат от <a className="text-white" href="https://ardpoacademy.ru/" alt="АРДПО">АРДПО</a> объемом 30 часов.
                  </p>
                </div>
                <div
                    className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
                    data-wow-delay="0.2s"
                >
                  <div className="featured-box style-4">
                    <img className="justify-content-center" height="200px" loading="lazy"
                         alt="Курс: «Мастерство формирования клиентского запроса»"
                         src="images/cert.jpg" role="presentation"/>
                  </div>
                </div>
              </div>
              <div className="row gy-3 mt-4 text-white text-4">
                <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                  <p className="fw-500 mb-0">Продолжительность:</p>
                  <p className="fw-600 mb-0">11 занятий по 2 часа</p>
                </div>
                <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
                  <p className="fw-500 mb-0">Частота встреч:</p>
                  <p className="fw-600 mb-0">Раз в неделю</p>
                </div>
                <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                  <p className="fw-500 mb-0">Формат:</p>
                  <p className="fw-600 mb-0">Online, Zoom</p>
                </div>
                <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                  <p className="fw-500 mb-0">Сертификация:</p>
                  <p className="fw-600 mb-0">30 часов АРДПО</p>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
      </section>
  );
};

export default About;
