import React, {useState} from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Modal from "react-modal";

const Education = () => {

    const handleDragStart = (e) => e.preventDefault();
    
    const items = [
      <img height="200px" loading="lazy" alt="101 ТА" onClick={() => openModal("images/diplomas/101-ta.webp", "101 ТА")} src="images/diplomas/small/101-ta.avif" onDragStart={handleDragStart} role="presentation"/>,
      <img height="200px" loading="lazy" alt="Akkreditaciya polimodalnaya psihoterapiya" onClick={() => openModal("images/diplomas/Akkreditaciya polimodalnaya psihoterapiya.webp", "Akkreditaciya polimodalnaya psihoterapiya")} src="images/diplomas/small/Akkreditaciya polimodalnaya psihoterapiya.webp" onDragStart={handleDragStart} role="presentation"/>,
      <img height="200px" loading="lazy" alt="Арт Терапия" onClick={() => openModal("images/diplomas/art.webp", "Арт Терапия")} src="images/diplomas/small/art.avif" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Geshtalt travma I ptsr" onClick={() => openModal("images/diplomas/Geshtalt travma i ptsr SHoshina Aleksandra.webp", "Geshtalt travma I ptsr")} src="images/diplomas/small/Geshtalt travma i ptsr SHoshina Aleksandra.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Djon maknil TA" onClick={() => openModal("images/diplomas/Djon maknil TA.webp", "Djon maknil TA")} src="images/diplomas/small/Djon maknil TA.avif" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Diplom" onClick={() => openModal("images/diplomas/Diplom.webp", "Diplom")} src="images/diplomas/small/Diplom.avif" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Imaton 101 TA" onClick={() => openModal("images/diplomas/Imaton 101 TA.webp", "Imaton 101 TA")} src="images/diplomas/small/Imaton 101 TA.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Imaton Otnosheniya s mamoi" onClick={() => openModal("images/diplomas/Imaton Otnosheniya s mamoi.webp", "Imaton Otnosheniya s mamoi")} src="images/diplomas/small/Imaton Otnosheniya s mamoi.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="OLT sertifikat" onClick={() => openModal("images/diplomas/OLT-sertifikat-SHoshina-B-S-67.webp", "OLT sertifikat")} src="images/diplomas/small/OLT sertifikat SHoshina B-S-67.avif" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="perinatalnaya psihologiya" onClick={() => openModal("images/diplomas/perinatalnaya psihologiya.webp", "perinatalnaya psihologiya")} src="images/diplomas/small/perinatalnaya psihologiya.avif" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Profor" onClick={() => openModal("images/diplomas/Profor.webp", "Profor")} src="images/diplomas/small/Profor.jpg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Psihiatriya" onClick={() => openModal("images/diplomas/Psihiatriya SHoshina Aleksandra.webp", "Psihiatriya")} src="images/diplomas/small/Psihiatriya SHoshina Aleksandra.png" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Psihiatriya SHoshina Aleksandra" onClick={() => openModal("images/diplomas/Sertifikat Alfrid Lindle.webp", "Psihiatriya SHoshina Aleksandra")} src="images/diplomas/small/Sertifikat Alfrid Lindle.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="sertifikat bodinamika." onClick={() => openModal("images/diplomas/sertifikat bodinamika.webp", "sertifikat bodinamika.")} src="images/diplomas/small/sertifikat bodinamika.JPG" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="sertifikat travma identichnosti" onClick={() => openModal("images/diplomas/sertifikat travma identichnosti.webp", "sertifikat travma identichnosti")} src="images/diplomas/small/sertifikat travma identichnosti.jpeg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Sertifikat Uainhold" onClick={() => openModal("images/diplomas/Sertifikat Uainhold.webp", "Sertifikat Uainhold")} src="images/diplomas/small/Sertifikat Uainhold.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Sertifikat YAlom" onClick={() => openModal("images/diplomas/Sertifikat YAlom.webp", "Sertifikat YAlom")} src="images/diplomas/small/Sertifikat YAlom.png" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Supervizor" onClick={() => openModal("images/diplomas/Supervizor SHoshina.webp", "Supervizor")} src="images/diplomas/small/Supervizor SHoshina.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="TA IMATON 2 god obucheniya" onClick={() => openModal("images/diplomas/TA IMATON 2 god obucheniya.webp", "TA IMATON 2 god obucheniya")} src="images/diplomas/small/TA IMATON 2 god obucheniya.jpeg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="TA Imaton vsyo chasy" onClick={() => openModal("images/diplomas/TA Imaton vsyo chasy.webp", "TA Imaton vsyo chasy")} src="images/diplomas/small/TA Imaton vsyo chasy.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Teoriya privyazannosti TA" onClick={() => openModal("images/diplomas/Teoriya privyazannosti TA.webp", "Teoriya privyazannosti TA")} src="images/diplomas/small/Teoriya privyazannosti TA.jpeg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Udostoverenie intuitivnaya jivopis" onClick={() => openModal("images/diplomas/Udostoverenie intuitivnaya jivopis.webp", "Udostoverenie intuitivnaya jivopis")} src="images/diplomas/small/Udostoverenie intuitivnaya jivopis.jpg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Udostoverenie lyubovnyi treugolnik" onClick={() => openModal("images/diplomas/Udostoverenie lyubovnyi treugolnik.webp", "Udostoverenie lyubovnyi treugolnik")} src="images/diplomas/small/Udostoverenie lyubovnyi treugolnik.jpg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Udostoverenie Plassoterapiya" onClick={() => openModal("images/diplomas/Udostoverenie Plassoterapiya.webp", "Udostoverenie Plassoterapiya")} src="images/diplomas/small/Udostoverenie Plassoterapiya.jpg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Udostoverenie Plassoterapiya2" onClick={() => openModal("images/diplomas/Udostoverenie Plassoterapiya2.webp", "Udostoverenie Plassoterapiya2")} src="images/diplomas/small/Udostoverenie Plassoterapiya2.jpg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Udostoverenie semeinoe konsultiroanie" onClick={() => openModal("images/diplomas/Udostoverenie semeinoe konsultiroanie.webp", "Udostoverenie semeinoe konsultiroanie")} src="images/diplomas/small/Udostoverenie semeinoe konsultiroanie.jpg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Udostoverenie sistemnaya semeinaya psihoterapiya2" onClick={() => openModal("images/diplomas/Udostoverenie sistemnaya semeinaya psihoterapiya2.webp", "Udostoverenie sistemnaya semeinaya psihoterapiya2")} src="images/diplomas/small/Udostoverenie sistemnaya semeinaya psihoterapiya2.webp" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="Chlenstvo PPL" onClick={() => openModal("images/diplomas/CHlenstvo PPL.webp", "Chlenstvo PPL")} src="images/diplomas/small/CHlenstvo PPL.jpeg" onDragStart={handleDragStart} role="presentation" />,
      <img height="200px" loading="lazy" alt="SHkola pereresheniya" onClick={() => openModal("images/diplomas/SHkola pereresheniya.webp", "SHkola pereresheniya")} src="images/diplomas/small/SHkola pereresheniya.webp" onDragStart={handleDragStart} role="presentation" />
    ];

    const Gallery = () => {
      const responsive = {
        0: { items: 2 },
        568: { items: 6 },
        1024: { items: 9},
      };
      return (
          <AliceCarousel mouseTracking 
                         items={items} 
                         responsive={responsive}
                         controlsStrategy="alternate"/>
      );
    }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState();
  const [modalImageAlt, setModalImageAlt] = useState();

  function openModal(_src, _alt) {
    // console.log("Education Modal opened")
    setModalImageSrc(_src);
    setModalImageAlt(_alt);
    setIsOpen(true);
  }
  function getImageTag(){
    return <img src={modalImageSrc}
                alt={modalImageAlt}
                onClick={(e) => e.stopPropagation()}
    />;
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (<>
      <section id="education" className="section bg-light">
        <div className="container">
          {/* Heading */}
          <p className=" text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Образование</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Сертификация и дипломы
          </h2>
          {/* Heading end*/}
          <Gallery/>
          <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              ariaHideApp={false}
              contentLabel="Education"
          >
            <div className="portfolio-image-modal" onClick={() => setIsOpen(false)}>
              {getImageTag()}
              <button
                  type="button"
                  className="btn-close btn-close-white opacity-10"
                  onClick={() => setIsOpen(false)}
              ></button>
            </div>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default Education;
