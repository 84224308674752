import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Common/Tooltip";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import easydropdown from 'easydropdown';
import { switchCurrency, getSavedCurrency, calculatePrices } from "../../Common/CurrencySwitcher"

const HomeHeader = ({ textWhite , setIndividualPrice, setScriboPrice, setPairPrice}) => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  useEffect(() => {
      const currencySelector = document.getElementById('currency-select');
      // console.log(currencySelector);
      easydropdown(currencySelector, { callbacks: { onSelect: oncurrencyChanged} });
      var prices = calculatePrices();
      setIndividualPrice(prices.individualPrice);
      setScriboPrice(prices.scriboPrice);
      setPairPrice(prices.pairPrice);
      var initialCurrency = getSavedCurrency();
      if (initialCurrency) {
        currencySelector.value = initialCurrency;
      }
  });

  function oncurrencyChanged(value){
    switchCurrency(value);
    var prices = calculatePrices();
    setIndividualPrice(prices.individualPrice); 
    setScriboPrice(prices.scriboPrice);
    setPairPrice(prices.pairPrice);
  }

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      if (
        document.body.scrollTop > 1 ||
        document.documentElement.scrollTop > 1
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-none navbar-overlay  bg-transparent border-bottom-0 text-5 fw-600 " +
          (stickyHeader ? "sticky-on-top " : " ") +
          (textWhite
            ? "navbar-overlay navbar-text-light"
            : "navbar-overlay-dark")
        }
      >
        <div className="container">
          {/* Logo */}
          <Link
            className="logo cursor-pointer"
            title="Александра Шошина, Психолог"
            smooth="easeInOutQuint"
            duration={scrollDuration}
            to="home"
            onClick={(e) => {
              e.preventDefault();
              setIsNavModalClose(true);
            }}
          >
            <img
              src={textWhite ? "images/logo.webp" : "images/logo.webp"}
              alt="Александра Шошина, Психолог"
              height="60px"
            />
          </Link>
          {/* Logo End */}
          <div
            className={"text-3 ms-auto me-2 " + (textWhite ? "text-white" : "")}
          >
            <select name="currency" id="currency-select">
              <option data-ref="icon" value="AED"><span class="fi fi-ae"></span> AED</option>
              <option data-ref="icon" value="RUB"><span class="fi fi-ru"></span> РУБ</option>
              <option data-ref="icon" value="USD"><span class="fi fi-us"></span> USD</option>
              <option data-ref="icon" value="EUR"><span class="fi fi-eu"></span> EUR</option>
            </select>
          </div>
          <div
            className={"text-3 me-2 " + (textWhite ? "text-white" : "")}
          >
            <Tooltip text="Instagram" placement="top">
              <a
                  href="https://www.instagram.com/a.i.shoshina/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                @a.i.shoshina
              </a>
            </Tooltip>
          </div>
          <button
            className={
              "navbar-toggler collapsed " + (isNavModalClose ? "" : "show")
            }
            type="button"
            onClick={() => setIsNavModalClose(!isNavModalClose)}
          >
            <span />
            <span />
            <span />
          </button>
          <div
            id="header-nav"
            className={
              "collapse navbar-collapse " + (isNavModalClose ? "" : "show")
            }
          >
            <div className="d-flex flex-column h-100 align-items-center justify-content-center">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="home"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Домашняя страница
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="about"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Обо мне
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                      className="nav-link cursor-pointer"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      activeClass="active"
                      spy
                      to="education"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                  >
                    Образование
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="services"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Услуги и цены
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                      className="nav-link cursor-pointer"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      activeClass="active"
                      spy
                      to="course"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                  >
                    Курс: «Мастерство формирования клиентского запроса»
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="clients"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Клиентам
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="faq"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Популярные вопросы
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="contact"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Связаться со мной
                  </Link>
                </li>
              </ul>
              <ul className="social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex mt-4">
                <li className="social-icons-telegram">
                  <Tooltip text="Telegram" placement="top">
                    <a
                        href="https://t.me/A_Shoshina"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <i className="fab fa-telegram" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-whatsapp">
                  <Tooltip text="WhatsApp" placement="top">
                    <a
                        href="https://wa.link/0jt9a0"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <i className="fab fa-whatsapp" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-instagram">
                  <Tooltip text="Instagram" placement="top">
                    <a
                        href="https://www.instagram.com/a.i.shoshina/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default HomeHeader;
