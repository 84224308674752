import {appliedConfig, introBackgroundConfig} from "../../config/commonConfig";

import AboutUs from "../Home/About";
import Services from "../Home/Services";
import Education from "../Home/Education";
import Clients from "../Home/Clients";
import Contact from "../Home/Contact";
import Course from "../Home/Course";

import Footer from "../Common/Footer";
import { Tooltip } from "../Common/Tooltip";
import CallToAction from "../Common/CallToAction";
import FAQs from "../Home/FAQs";
import HomeHeader from "../themes/fullScreen/HomeHeader";
import HomeIntro from "../themes/fullScreen/HomeIntro";
import {useEffect, useState} from "react";
import WOW from "wowjs";

const Home = () => {

    const appliedIntro = appliedConfig.appliedIntro;

    const handleNavClick = (section) => {
        document.getElementById(section).scrollIntoView({ behavior: "smooth" });
    };

    const [scrollTopVisible, setScrollTopVisible] = useState(false);
    const [individualPrice, setIndividualPrice] = useState("...");
    const [scriboPrice, setScriboPrice] = useState("...");
    const [pairPrice, setPairPrice] = useState("...");

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)

        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, []);

    const checkScrollTop = () => {
        let scrollTopBtn = document.getElementById("back-to-top");

        if (scrollTopBtn) {
            if (
                document.body.scrollTop > 400 ||
                document.documentElement.scrollTop > 400
            ) {
                setScrollTopVisible(true);
            } else {
                setScrollTopVisible(false);
            }
        }
    };

    if (typeof window !== "undefined") {
        window.addEventListener("scroll", checkScrollTop);
    }


    return (<div className="relative">
        <div id="main-wrapper">
            <HomeHeader
                textWhite={appliedIntro === introBackgroundConfig.image}
                setIndividualPrice={setIndividualPrice}
                setScriboPrice={setScriboPrice}
                setPairPrice={setPairPrice}
            ></HomeHeader>

            <div id="content" role="main">
                <HomeIntro/>
                <AboutUs/>
                <Education/>
                <CallToAction/>
                <Services individualPrice={individualPrice} scriboPrice={scriboPrice} pairPrice={pairPrice}/>
                <Course/>
                <Clients/>
                <FAQs/>
                <Contact/>
            </div>
            <Footer handleNavClick={handleNavClick}></Footer>
        </div>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
      <span
          id="back-to-top"
          className={ scrollTopVisible ? "display-inline rounded-circle" : "display-none rounded-circle" }
          // className="rounded-circle"
          // style={{ display: scrollTopVisible ? "inline" : "none" }}

          onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
          }}
      >
        <i className="fas fa-arrow-up"></i>
      </span>
        </Tooltip>
    </div>);
}

export default Home;