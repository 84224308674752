import {useAccordionButton} from "react-bootstrap/AccordionButton";
import React from "react";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
        <i
            type="button" className="fa fa-check-circle text-start text-xl-end"
            onClick={decoratedOnClick}
        >
            {" "}{children}
        </i>
    );
}

export default CustomToggle
  