import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../../config/commonConfig";
import { Tooltip } from "../../Common/Tooltip";
import {useNavigate} from "react-router-dom";

const CourcesHeader = ({ textWhite }) => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      if (
        document.body.scrollTop > 1 ||
        document.documentElement.scrollTop > 1
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const navigate = useNavigate();
  
  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-none navbar-overlay bg-transparent border-bottom-0 text-5 fw-600 " +
          (stickyHeader ? "sticky-on-top " : " ") +
          (textWhite
            ? "navbar-overlay navbar-text-light"
            : "navbar-overlay-dark navbar-text-light text-white")
        }
      >
        <div className="container">
          {/* Logo */}
          <Link
            className="logo cursor-pointer"
            title="Александра Шошина, Психолог"
            smooth="easeInOutQuint"
            duration={scrollDuration}
            to="home"
            onClick={(e) => {
              e.preventDefault();
              setIsNavModalClose(true);
            }}
          >
            <img
              src={textWhite ? "images/logo-white.png" : "images/logo-white.png"}
              alt="Александра Шошина, Психолог"
              height="60px"
            />
          </Link>
          {/* Logo End */}
          <div
            className={"text-3 ms-auto me-2 cursor-pointer" + (textWhite ? "text-white" : "")}
          >
            <Tooltip text="На Главную" placement="top">
              <span
                  onClick={() => {
                    var referrer = document.referrer;
                    if(referrer === ""){
                      navigate("/")
                    } else {
                      navigate(-1)
                    }
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <i className="far fa-arrow-alt-circle-left"></i><span>{"  "}</span> Вернуться Назад
              </span>
            </Tooltip>
          </div>
          <button
            className={
              "navbar-toggler collapsed " + (isNavModalClose ? "" : "show")
            }
            type="button"
            onClick={() => setIsNavModalClose(!isNavModalClose)}
          >
            <span />
            <span />
            <span />
          </button>
          <div
            id="header-nav"
            className={
              "collapse navbar-collapse " + (isNavModalClose ? "" : "show")
            }
          >
            <div className="d-flex flex-column h-100 align-items-center justify-content-center">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    onClick={() => navigate(-1)}
                  >
                    Вернуться Назад на Главную
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="about"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Курс: «Мастерство формирования клиентского запроса»
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                      className="nav-link cursor-pointer"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      activeClass="active"
                      spy
                      to="author"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                  >
                    Об авторе курса
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="benefits"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Возможности курса
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                      className="nav-link cursor-pointer"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      activeClass="active"
                      spy
                      to="program"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                  >
                    Программа курса
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cursor-pointer"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    activeClass="active"
                    spy
                    to="schedule"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Расписание курса
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                      className="nav-link cursor-pointer"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      activeClass="active"
                      spy
                      to="payment"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                  >
                    Записаться на курс
                  </Link>
                </li>
                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*      className="nav-link cursor-pointer"*/}
                {/*      smooth="easeInOutQuint"*/}
                {/*      duration={scrollDuration}*/}
                {/*      activeClass="active"*/}
                {/*      spy*/}
                {/*      to="contact"*/}
                {/*      onClick={(e) => {*/}
                {/*        e.preventDefault();*/}
                {/*        setIsNavModalClose(true);*/}
                {/*      }}*/}
                {/*  >*/}
                {/*    Записаться на курс*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
              <ul className="social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex mt-4">
                <li className="social-icons-telegram">
                  <Tooltip text="Telegram" placement="top">
                    <a
                        href="https://t.me/A_Shoshina"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <i className="fab fa-telegram" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-whatsapp">
                  <Tooltip text="WhatsApp" placement="top">
                    <a
                        href="https://wa.link/0jt9a0"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <i className="fab fa-whatsapp" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-instagram">
                  <Tooltip text="Instagram" placement="top">
                    <a
                        href="https://www.instagram.com/a.i.shoshina/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default CourcesHeader;
