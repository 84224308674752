import React, {useRef, useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import {toast, ToastContainer} from "react-toastify";

import agreement from "../../documents/Agreement.pdf";
import emailjs from "@emailjs/browser";
import CustomToggle from "../Common/Toggle";

const Clients = () => {

  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);
  
  function getTicketNumber() {
    const date = new Date();
    const h = date.getHours();
    const min = date.getMinutes();
    const s = date.getSeconds();
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return y + "/" + m + "/" + d + "/" + h + "/" + min + "/" + s;
  }
  
  const signAgreement = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
        .sendForm(
            "service_cdvobw4",
            "template_sre12wi",
            form.current,
            "gtb8u8xOc4z0EkghJ"
        )
        .then(
            (result) => {
              document.getElementById("agreement-form").reset();
              toast.success("Согласие успешно подписано!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              // console.log(result.text);
              setSendingMail(false);
            },
            (error) => {
              toast.error("Что-то пошло не так. Не удалось подписать согласие.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              // console.log(error.text);
              setSendingMail(false);
            }
        );
  };
  
  const rules = [
    {
      title: "Правило первичных (установочных) сессий",
      details:
          "Установочные сессии необходимы, чтобы понять, насколько Вам подходит стиль и методы моей работы. " +
          "Также на данных сессиях Вы и я принимаем решения насколько наш психотерапевтический союз может быть " +
          "эффективен в рамках решения сформулированного запроса. Количество первичных сессий - от 1 до 5 встреч " +
          "(продолжительность сессии 50 минут).",
    },
    {
      title: "Правило длительной формы работы",
      details:
          "Если Вы выбираете длительный формат работы, то наши встречи будут проходить с частотой один раз в неделю. " +
          "Я готова рассматривать возможность более частых встреч (два раза в неделю), если Вы и я считаем, что " +
          "Вы нуждаетесь в этом. Более редкие встречи снижают эффективность работы " +
          "(данный вариант обсуждается в индивидуальном порядке).",
    },
    {
      title: "Правило регулярности и частоты встреч",
      details:
          "Регулярность и частота способствуют более значительным и стойким изменениям. Время наших встреч мы " +
          "определяем на первой встрече и в дальнейшем это время становится вашим фиксированным. Если у Вас нет " +
          "возможности придерживаться постоянного графика, мы подберем оптимальное решение.",
    },
    {
      title: "Правило раннего начала сессии",
      details:
          "Если Вы готовы начать сессию раньше назначенного времени, пожалуйста, сообщите мне об этом. Если у меня " +
          "будет такая возможность, то мы раньше начнем и раньше закончим. В альтернативном случае " +
          "(если у меня нет возможности начать сессию раньше) Вам будет необходимо подождать начала Вашего сеанса.",
    },
    {
      title: "Правило отмены",
      details:
          "Встреча может быть отменена или перенесена за 24 часа до предполагаемого времени начала сеанса, выбранного " +
          "по общей договоренности. При более поздней отмене пропущенный сеанс оплачивается.",
    },
    {
      title: "Правило продления сессии",
      details:
          "Если сессия продлевается по согласию обеих сторон (клиента и психолога), то за дополнительное время " +
          "(превышающее ранее оговоренную продолжительность сессии) Вам необходимо будет заплатить в расчёте из " +
          "тарифа на услугу.",
    },
    {
      title: "Правило опаздания",
      details:
          "Если Вы опаздываете к назначенному времени, наша встреча укорачивается на длину вашего опоздания. " +
          "При моей личной возможности, я могу скомпенсировать время Вашего опоздания на 5 минут. " +
          "Но, в любом случае, с наступлением следующего часа мы заканчиваем.",
    },
    {
      title: "Правило личной безопасности клиента",
      details:
          "Если то, что я предлагаю в рамках нашей работы, кажется Вам небезопасным, не нужно насиловать себя, лучше " +
          "мы вместе посмотрим, в чем здесь Ваша трудность.",
    },
    {
      title: "Правило (не)выполненного домашнего задания",
      details:
          "Для нас важно не столько выполненность домашнего задания, а то, КАК Вы его выполнили или не выполнили, это " +
          "в данном случае более информативно для нашей работы, чем конкретный результат или Ваше послушание.",
    },
    {
      title: "Правило открытого обсуждения терапии",
      details:
          "Я всегда готова обсудить с Вами то, как идет наша работа.",
    },
    {
      title: "Правило «прощальной встречи»",
      details:
          "Если вы хотите завершить психотерапевтическую работу, призываю Вас обсудить это заранее. Мы устроим " +
          "прощальную встречу. На последней встрече мы подводим итоги работы, завершаем незавершённые темы, " +
          "обмениваемся впечатлениями о пройденном пути.",
    },
    {
      title: "Правило экологичного взаимодействия",
      details:
          "Я не приму Вас и перенесу встречу, если в назначенное время Вы придете в алкогольном или наркотическом " +
          "опьянении. Процесс терапии должен быть безопасен, как для Вас, так и для меня.",
    }
  ];
  
  const firstSetOfRules = rules.filter(
      (rule, index) => index % 2 === 0
  );

  const secondSetOfRules = rules.filter(
      (rule, index) => index % 2 !== 0
  );

  return (
    <>
      <section id="clients" className={"section"}>
        <div className="container text-center">
          <div className="col-lg-12">
            {/* Heading */}
            <p className="mb-2 wow fadeInUp">
              <span className="bg-primary text-dark px-2">Клиентам</span>
            </p>
            <h2 className="text-10 fw-600 mb-5 wow fadeInUp">
              Важная информация для клиентов
            </h2>
            {/* Heading end*/}
          </div>
            {/* Content */}
          <div className="row">
            <div className="col-lg-6">
              <Accordion flush defaultActiveKey="0">
                {firstSetOfRules.length > 0 &&
                    firstSetOfRules.map((rule, index) => (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header className="text-start text-xl-end">
                            <CustomToggle eventKey="1">{rule.title}</CustomToggle>
                          </Accordion.Header>
                          <Accordion.Body
                              dangerouslySetInnerHTML={{ __html: rule.details }}
                          ></Accordion.Body>
                        </Accordion.Item>
                    ))}
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion flush defaultActiveKey="0">
                {secondSetOfRules.length > 0 &&
                    secondSetOfRules.map((rule, index) => (
                        <Accordion.Item eventKey={index} key={firstSetOfRules.length+index}>
                          <Accordion.Header className="text-start text-xl-end">
                            <CustomToggle eventKey="1">{rule.title}</CustomToggle>
                          </Accordion.Header>
                          <Accordion.Body
                              dangerouslySetInnerHTML={{ __html: rule.details }}
                          ></Accordion.Body>
                        </Accordion.Item>
                    ))}
              </Accordion>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <div className="fade show card h-100 shadow-lg rounded-5 col-lg-8" tabIndex="0">
              <form
                  id="agreement-form"
                  className="row card-body"
                  method="post"
                  ref={form}
                  onSubmit={signAgreement}
              >
                <div className="col-lg-1"></div>
                <div className="col-lg-6 mt-4">
                  {/* Contact Form */}
                    <div className="row g-4">
                      <div className="col-12 text-start">
                        <label className="form-label" htmlFor="user_name">
                          Ваше Имя и Фамилия:
                        </label>
                        <input
                            id="user_name"
                            name="user_name"
                            type="text"
                            className="form-control py-1"
                            required
                        />
                      </div>
                      <div className="col-12 text-start">
                        <label className="form-label" htmlFor="user_email">
                          Ваш Email:
                        </label>
                        <input
                            id="user_email"
                            name="user_email"
                            type="email"
                            className="form-control py-1"
                            required
                        />
                      </div>
                      <ToastContainer />
                      <input
                          id="ticket_number"
                          name="ticket_number"
                          className="visually-hidden"
                          required
                          value={getTicketNumber()}
                          readOnly
                      />
                    </div>
                  {/* Contact Form end */}
                </div>
                <div
                    className="col-lg-5 d-flex align-items-center justify-content-center"
                    data-wow-delay="1s"
                >
                  <div className="col-lg-12">
                    {" "}
                    <a
                        className="btn btn-outline-light shadow-none rounded-0"
                        href={agreement}
                        rel="noopener noreferrer"
                    >
                      <img
                          className="img-fluid"
                          width="100px"
                          src="images/rules.webp"
                          title="Rules"
                          alt="Rules"
                          loading="lazy"
                      />{" "}
                      <p className="text-black-50">Просмотреть Согласие</p>
                    </a>
                  </div>
                </div>
                <div className="col-lg-1 col-sm"></div>
                <div className="text-lg-start text-sm-center col-lg-11 col-sm-12 mt-4 mb-lg-4">
                  <button
                      id="agreement-submit-btn"
                      className="btn btn-primary rounded-3 p-2 text-2"
                      type="submit"
                  >
                    {sendingMail ? (
                        <>
                        <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                          Отправляем......
                        </>
                    ) : (
                        <>
                          Согласен(-на) с правилами{" "}
                          <span className="ms-3">
                          <i className="fas fa-arrow-right" />
                        </span>
                        </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
