import React from "react";

const Author = () => {

  return (
    <>
      <section id="author" className={"section"}>
        <div className="container text-center">
          <div className="col-lg-12">
            {/* Heading */}
            <p className="mb-2 wow fadeInUp">
              <span className="bg-primary text-dark px-2">Об авторе курса</span>
            </p>
            <h2 className="text-10 fw-600 mb-5 wow fadeInUp">
              Кто ведет курс?
            </h2>
            {/* Heading end*/}
          </div>
            {/* Content */}
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1">
              <div className="bg-light rounded-pill d-inline-block p-3 shadow-lg wow zoomIn">
                <img
                    className="img-fluid rounded-pill d-block"
                    src="/images/ai_photo_alt.JPG"
                    title="Алекандра Шошина, Психолог"
                    alt="Алекандра Шошина, Психолог"
                    width="207px"
                    height="104px"
                />
              </div>
            </div>
            <div className="col-lg-7 text-start">
              <p>
                Аккредитованный полимодальный психотерапевт, психолог, преподаватель психологии.
                Действительный член Общероссийской Профессиональной Психотерапевтической Лиги (ОППЛ),
                член Санкт-Петербургской Организации Транзактного Анализа (СОТА), обучающий личный терапевт КОЛТ ОППЛ,
                супервизор, преподаватель ОППЛ.
              </p>
              <div className="row gy-3 mt-4">
                <div className="col-6 col-lg-6 wow fadeInUp">
                  <p className="text-muted fw-500 mb-0">Клиенты:</p>
                  <p className="text-4 text-dark fw-600 mb-0">> 2000 клиентов</p>
                </div>
                <div className="col-6 col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
                  <p className="text-muted fw-500 mb-0">Личная супервизия:</p>
                  <p className="text-4 fw-600 mb-0">> 200 часов</p>
                </div>
              </div>
              <div className="row gy-3 mt-4">
                <div className="col-6 col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                  <p className="text-muted fw-500 mb-0">Личная терапия:</p>
                  <p className="text-4 text-dark fw-600 mb-0">> 200 часов</p>
                </div>
                <div className="col-6 col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
                  <p className="text-muted fw-500 mb-0">Профессиональная подготовка:</p>
                  <p className="text-4 text-dark fw-600 mb-0">> 10900 часов</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Author;
