import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import {Parallax} from "react-parallax";

const Payment = () => {

  return (<>
        <section id="payment">
          <div className="hero-wrap">
            <div className="hero-mask opacity-8 bg-dark" />
            <Parallax
                bgImage="images/payment_bg.jpg"
                bgImageAlt="Course"
                strength={200}
                loading="lazy"
            >
              <div className="container hero-content section text-center text-white">
                <div className="col-lg-12">
                  {/* Heading */}
                  <p className="mb-2 wow fadeInUp">
                    <span className="bg-white text-dark px-2">Записаться на курс</span>
                  </p>
                  <h2 className="text-10 fw-600 mb-5 wow fadeInUp">
                    Оставить заявку на прохождение курса. 
                  </h2>
                  {/* Heading end*/}
                </div>
                {/* Content */}
                <div className="row">
                  <div className="col-lg-4"/>
                  <div className="col-lg-4">
                    <a className="btn btn-light rounded-0 smooth-scroll wow rubberBand"
                          href="https://ardpoacademy.ru/masterstvozaprosa#rec590175879" >Перейти к записи</a>
                  </div>
                  <div className="col-lg-4"/>
                </div>
              </div>
            </Parallax>
          </div>
        </section>
    </>
  );
};

export default Payment;
