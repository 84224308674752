import "./App.scss";
import HomePage from "./components/Navigation/Home";
import CoursesPage from "./components/Navigation/Courses";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/courses" element={<CoursesPage />}/>           
        </Routes>
      </Router>
    </>
  );
}

export default App;
