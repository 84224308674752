import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "../Common/Tooltip";
import Accordion from "react-bootstrap/Accordion";

const Contact = () => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  function getTicketNumber() {
    const date = new Date();
    const h = date.getHours();
    const min = date.getMinutes();
    const s = date.getSeconds();
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return y + "/" + m + "/" + d + "/" + h + "/" + min + "/" + s;
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        "service_sim65fo",
        "template_wmbvg2i",
        form.current,
        "gtb8u8xOc4z0EkghJ"
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          toast.success("Сообщение успешно отправлено!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // console.log(result.text);
          setSendingMail(false);
        },
        (error) => {
          toast.error("Что-то пошло не так. Сообщение не было отправлено.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // console.log(error.text);
          setSendingMail(false);
        }
      );
  };

  return (
    <section id="contact" className="section bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">Связаться со мной</h2>
            <p className="text-4 mb-5">
              Выберите любой удобный для Вас способ связи
            </p>
            <h3 className="text-4 fw-600">
              <i className="fab fa-telegram" /> Telegram:
            </h3>
            <Tooltip text="Telegram" placement="top">
              <a
                  href="https://t.me/A_Shoshina"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <p className="text-4">A_Shoshina</p>
              </a>
            </Tooltip>
            <h3 className="text-4 fw-600">
              <i className="fab fa-whatsapp" /> WhatsApp:
            </h3>
            <Tooltip text="WhatsApp" placement="top">
              <a
                  href="https://wa.link/0jt9a0"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <p className="text-4">+7 (902) 192-19-19</p>
              </a>
            </Tooltip>
            <h3 className="text-4 fw-600">
              <i className="fab fa-instagram" /> Instagram:
            </h3>
            <Tooltip text="Instagram" placement="top">
              <a
                  href="https://www.instagram.com/a.i.shoshina/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <p className="text-4">@a.i.shoshina</p>
              </a>
            </Tooltip>
            <h3 className="text-4 fw-600">Email:</h3>
            <Tooltip text="Email" placement="top">
              <a
                  href="mailto:alexandra@shoshina.ru"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <p className="text-4">alexandra@shoshina.ru</p>
              </a>
            </Tooltip>
          </div>
          <div
            className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Записаться в лист ожидания
            </h2>
            {/* Contact Form */}
            <form
              id="contact-form"
              className="form-border"
              method="post"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-12">
                  <label className="form-label" htmlFor="user_name">
                    Ваше Имя и Фамилия:
                  </label>
                  <input
                    id="user_name"
                    name="user_name"
                    type="text"
                    className="form-control py-1"
                    required
                  />
                </div>
                <div className="col-6">
                  <label className="form-label" htmlFor="user_email">
                    Ваш Email:
                  </label>
                  <input
                    id="user_email"
                    name="user_email"
                    type="email"
                    className="form-control py-1"
                    required
                  />
                </div>
                <div className="col-6">
                  <label className="form-label" htmlFor="user_phone">
                    Номер Телефона:
                  </label>
                  <input
                      id="user_phone"
                      name="user_phone"
                      type="phone"
                      className="form-control py-1"
                      required
                  />
                </div>
                <div className="col-6">
                  <label className="form-label" htmlFor="days">
                    Удобные дни для консультации:
                  </label>
                  <input
                      id="days"
                      name="days"
                      type="text"
                      className="form-control py-1"
                      required
                  />
                </div>
                <div className="col-6">
                  <label className="form-label" htmlFor="time">
                    Удобное время для консультации:
                  </label>
                  <input
                      id="time"
                      name="time"
                      type="text"
                      className="form-control py-1"
                      required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="message">
                    Чем я могу Вам помочь?:
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="form-control py-1"
                    rows={4}
                    required
                    defaultValue={""}
                  />
                </div>
                <Accordion flush defaultActiveKey="0">
                  <Accordion.Item>
                    <Accordion.Header>Зачем этот лист?</Accordion.Header>
                    <Accordion.Body>
                      <p>Запись на индивидуальные и парные консультации пользуется популярностью. Запись идёт на 4-6 месяцев вперёд.
                        Появляющиеся свободные окошки бронируют в течении 1-2 дней. Многие потенциальные клиенты не успевают попасть на консультации или упускают свой шанс в суете будней.
                        Лист потенциальных клиентов создан для того, чтобы клиенты, которые планируют начать свою личную терапию у меня, могли в порядке очереди попасть в терапию и не упустить этот шанс.
                        Прошу Вас обратить внимание на то, что особенностью данной записи является отсутствие четких сроков ожидания.</p>

                      <p><b>Заявка Вас ни к чему не обязывает. </b>
                        При освобождении постоянного окошка для записи Вы в порядке очереди получите смс-оповещение. Просим Вас правильно указывать свой номер телефона для связи в заявке, чтобы мы точно смогли Вас оповестить.
                        Если Ваши планы на терапию изменились за время ожидания, то Вы всегда сможете в ответном сообщении об этом нам сообщить.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="col-12 text-center text-lg-start">
                  <button
                    id="submit-btn"
                    className="btn btn-dark rounded-0"
                    type="submit"
                  >
                    {sendingMail ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                        Отправляем......
                      </>
                    ) : (
                      <>
                        Отправить{" "}
                        <span className="ms-3">
                          <i className="fas fa-arrow-right" />
                        </span>
                      </>
                    )}
                  </button>
                </div>
                <ToastContainer />
                <input
                    id="ticket_number"
                    name="ticket_number"
                    className="visually-hidden"
                    required
                    value={getTicketNumber()}
                    readOnly
                />
              </div>
            </form>
            {/* Contact Form end */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
